import logo from './logo.svg';
import './App.css';
import Uplinks from './Views/Uplinks';
import Login from './Views/Login';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import React, { useEffect } from 'react';
import LWSPT from './LWSPT';

function App() {

	const [authenticated, setAuthenticated] = React.useState(false);

	useEffect(() => {
		new LWSPT();
		LWSPT.onAuthListeners.push(onAuth);
		LWSPT.instance.tryConnect();
	}, []);

	function onAuth () {
		setAuthenticated(true);

	}

	if(!authenticated) {
		return <Login />;
	}

	return (
		<div className="App">
			<Router>
				<Routes>
					<Route path="/login" element={<Login />} />
					<Route path="/uplinks" element={<Uplinks />} />
					<Route path="/" element={<Uplinks />} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
