/**
 * @brief Client WebSocket communication commands
 */
export const ClientWSCommands = {
    AUTH:           0x01,
    GETDEVICES:     0x10,
    GETRELAYS:      0x11,
    GETUPLINKS:     0x12,
    NEWDEVICE:      0x16,
    DATA:           0x20

}

/**
 * @brief Request id counter
 */
let reqid = 0;

export default class LWSPT {
    /**
     * @type {WebSocket|null}
     */
    ws = null;

    /**
     * @type {LWSPT|null}
     */
    static instance = null;

    static token = "";

    static authenticated = false;

    static onAuthListeners = [];
    static onMessageListeners = [];

    /**
     * @type {{id: number, cb: function}[]}
     */
    static requests = [];


    constructor() {
        if(LWSPT.instance)
            return;

        LWSPT.instance = this;

        this.wsOnOpen = this.wsOnOpen.bind(this);
        this.wsOnClose = this.wsOnClose.bind(this);
        this.wsOnMessage = this.wsOnMessage.bind(this);
        this.tryConnect = this.tryConnect.bind(this);
    }

    tryConnect () {
        let cookies = document.cookie;
        let cookieIndex = cookies.indexOf("login-token");
        if(cookieIndex >= 0) {
            let vindex = cookieIndex + "login-token=".length;
            let token = "";
            for(let i = vindex; i < cookies.length; i++) {
                if(cookies.charAt(i) === ';'.charAt(0)) {
                    break;
                }
                token += cookies.charAt(i);
            }
            LWSPT.token = token;
            this.wsOpen();
        }
        else {
            // Token not found

        }

    }

    wsOpen () {
        this.ws = new WebSocket("wss://lwspt.softrain.fi:15884");

        this.ws.onopen = this.wsOnOpen;
        this.ws.onclose = this.wsOnClose;
        this.ws.onmessage = this.wsOnMessage;
    }

    /**
     * 
     * @param {Event} ev 
     */
    wsOnOpen (ev) {
        this.ws.send(JSON.stringify({
            cmd: 0x01,
            token: LWSPT.token
        }));
    }
    /**
     * 
     * @param {Event} ev 
     */
    wsOnClose (ev) {

    }
    /**
     * 
     * @param {Event} ev 
     */
    wsOnMessage (ev) {

        let jsn = JSON.parse(ev.data);
        if(!LWSPT.authenticated) {
            if(jsn.cmd === 0x01) {
                if(jsn.status) {
                    LWSPT.authenticated = true;
                    console.log("WS Token login successfull");
                    
                    LWSPT.onAuthListeners.forEach((e) => {
                        e();
                    })
                }
                else {
                    console.error("WS Token login failed");
                    return;
                }
            }
            else {
                return;
            }
        }

        for(let i = 0; i < LWSPT.requests.length; i++) {
            let req = LWSPT.requests[i];
            if(jsn.reqid === req.id) {
                req.cb(jsn);
                LWSPT.requests.splice(i, 1);
                break;
            }
        }


    }


     /**
     * @brief Requests data from server
     * @param {object} message 
     * @param {function} cb 
     */
     static request (message, cb) {
        let id = reqid++;
        LWSPT.instance?.ws.send(JSON.stringify({
            ...message,
            ...{reqid: id}
        }));

        LWSPT.requests.push({
            id: id,
            cb: cb
        });
    }

}