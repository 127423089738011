/**
 * @brief Adds a leading zero if necessary
 * @param {number} n 
 * @returns {string} Number with a leading zero
 */
export const addZ = (n) => {
    return n < 10 ? ('0' + n) : n;
} 

/**
 * @brief Formats a datetime to YYYY-MM-DDThh:ii:ss OR DD.MM.YYYY hh:ii:ss
 * @param {Date} date 
 * @param {'iso'|'fi'} format 
 * @returns {string} Formatted date
 */
export const formatDateTime = (date, format = 'iso') => {
    if(format === 'fi') { 
        return (`${addZ(date.getDate())}.${addZ(date.getMonth() + 1)}.${date.getFullYear()} ${addZ(date.getHours())}:${addZ(date.getMinutes())}:${addZ(date.getSeconds())}`);
    }
    // ISO date    
    return (`${date.getFullYear()}-${addZ(date.getMonth() + 1)}-${addZ(date.getDate())}T${addZ(date.getHours())}:${addZ(date.getMinutes())}:${addZ(date.getSeconds())}`);

}

/**
 * @brief Formats a date to YYYY-MM-DD OR DD.MM.YYYY
 * @param {Date} date 
 * @param {'iso'|'fi'} format 
 * @returns {string} Formatted date
 */
 export const formatDate = (date, format = 'iso') => {
    if(format === 'fi') { 
        return (`${addZ(date.getDate())}.${addZ(date.getMonth() + 1)}.${date.getFullYear()}`);
    }
    // ISO date    
    return (`${date.getFullYear()}-${addZ(date.getMonth() + 1)}-${addZ(date.getDate())}`);

}


export const formatTime = (timestamp) => {
    return addZ(Math.floor(timestamp/3600)) + "h " + addZ(Math.floor(timestamp/60) % 60) + "m " + addZ(timestamp % 60) + "s";
}
