import { Box, Card } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect } from "react";
import LWSPT, { ClientWSCommands } from "../LWSPT";
import { formatDateTime } from "../Misc/Helpers";

const columns = [
    { field: 'date', headerName: 'Date', flex: 1},
    { field: 'eui', headerName: 'Device', flex: 1},
    { field: 'payload', headerName: 'Payload (HEX)', flex: 1},
    { field: 'location', headerName: 'Location', flex: 1},
    { field: 'heading', headerName: 'Heading', flex: 1},
    { field: 'lrrid', headerName: 'Relay ID', flex: 1},
    { field: 'lrrrssi', headerName: 'Relay RSSI', flex: 1},

]
/*
const rows = [
    {
        id: 0,
        date: formatDateTime(new Date(), "fi"),
        eui: "ABCDEF0123456789",
        location: "25.3153, 64.31134",
        heading: "30",
        lrrid: "0123ABCD",
        lrrloc: "25.833, 64.3224",
    }
]
*/


export default function Uplinks (props) {

    const [rows, setRows] = React.useState([]);


    function fetchPage (page) {
        LWSPT.request({
            cmd: ClientWSCommands.GETUPLINKS,
            start: 0,
            count: 200
        }, (data) => {
            if(data.uplinks.length > 0) {
                setRows(data.uplinks.map(e => {
                    return {
                        id: e.id,
                        date: formatDateTime(new Date(e.date), 'fi'),
                        eui: e.device,
                        payload: e.payload,
                        location: e.latitude + ", " + e.longitude,
                        heading: e.heading,
                        lrrid: e.relay,
                        lrrrssi: e.lrrrssi
                    }
                }));
            }
        })
    }

    useEffect(() => {
        fetchPage(0);
    }, []);

    return <Card sx={{height: '90vh'}}>
        {
            rows.length > 0 &&
            <DataGrid
                pageSize={20}
                rowsPerPageOptions={[20]}
                columns={columns}
                rows={rows}
            />
        }
    </Card>

}