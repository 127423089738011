import { Box, Button, Card, TextField, Typography } from "@mui/material";
import React from "react";
import LWSPT from "../LWSPT";


export default function Login (props) {

    const [username, setUsername] = React.useState("");
    const [password, setPassword] = React.useState("");

    /**
     * @brief On login
     */
    function onLogin () {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            
            body: JSON.stringify({
                username: username,
                password: password
            })
        };
        fetch("https://lwspt.softrain.fi:15885", requestOptions).then(resp => resp.json()).then((data) => {
            if(data.status === true) {
                LWSPT.token = data.token;
                let cookie = "login-token=" + LWSPT.token + ";max-age=" + data.maxAge;
                document.cookie = cookie;
                console.log("Logged in");
                LWSPT.instance.tryConnect();
            }
            else {
                // Login failed
                console.warn("Invalid username or password");
            }
        });
    }


    return <Card>
        <Box>
            <Typography>Username</Typography>
            <TextField type={"text"} value={username} onChange={(e) => setUsername(e.target.value)} />
            <Typography>Password</Typography>
            <TextField type={"password"} value={password} onChange={(e) => setPassword(e.target.value)} />
        </Box>
        <Button onClick={() => onLogin()}>Login</Button>
    </Card>

}